<template>
	<div class="budget-container">
		<div class="chart-section__bar bar-budget budget">
			<canvas
				id="chart"
				class="budget--square"
				v-click-outside="removeTooltip"
				@click="removeTooltip"
			></canvas>
			<div class="square"></div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Chart from 'chart.js';
import pattern from 'patternomaly';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatNumber } from '@/helpers/generic';

Chart.plugins.unregister(ChartDataLabels);

export default {
	name: 'BudgetBySectorChart',
	data() {
		return {
			chart: null
		};
	},
	computed: {
		...mapGetters(['padFilters']),
		...mapState({
			data: store => store.pad.data,
			keys: store => store.pad.keys
		}),
		colors() {
			const paramsColors = this.$route.query.colors;

			if (!paramsColors) return this.keys;

			return this.keys.map((key, index) => {
				if (paramsColors[index]) key.color = `#${paramsColors[index]}`;
				return key;
			})
		}
	},
	watch: {
		data: {
			handler: 'setChart',
			deep: true
		}
	},
	destroyed() {
		this.padFilters.sector = null;
	},
	methods: {
		setChart() {
			this.$nextTick(() => {
				this.removeTooltip();

				if (this.chart != null) {
					this.chart.destroy();
				}

				if (!this.data) return;

				var ctx = document.getElementById('chart').getContext('2d');
				ctx.height = 700;

				let max = Math.max.apply(
					Math,
					this.data.map(singleData => singleData.sum)
				);
				max = max + max * 0.1;

				const labels = this.data.map(singleData => singleData.label);
				const datasets = this.colors.map(key => {
					const dataset = {
						backgroundColor: key.color,
						data: this.data.map(singleData => singleData.values[key.slug] ? parseFloat(singleData.values[key.slug]) : 0)
					};

					if (key.slug === 'other') {
						dataset.backgroundColor = pattern.draw(
							'diagonal',
							'transparent',
							'#979797'
						);

						dataset.data = this.data.map(singleData => {
                            const singleDataValues = JSON.parse(JSON.stringify(singleData.values));
							return singleDataValues[key.slug] ? (parseFloat(singleDataValues[key.slug]) * 0.1) : 0;
                        });
					}

					return dataset;
				});

				const _this = this;
				const customTooltip = this.generateTooltip();

				this.chart = new Chart(ctx, {
					type: 'bar',
					plugins: [pattern],
					data: {
						labels,
						datasets
					},
					options: {
						responsive: true,
						events: ['click', 'mouseout'],
						maintainAspectRatio: false,
						layout: {
							padding: {
								top: 50
							}
						},
						legend: {
							display: false
						},
						tooltips: {
							enabled: false,
							custom: customTooltip
						},
						animation: {
							onComplete: function() {
								this.options.animation.onComplete = null;
								_this.$emit('onLoad');
							}
						},
						scales: {
							scaleShowGridLines: false,
							yAxes: [
								{
									stacked: true,
									ticks: {
										beginAtZero: true,
										display: false,
										stepSize: max / 50
										// max
									},
									gridLines: {
										drawBorder: true,
										stepSize: 5,
										drawTicks: false,
										zeroLineWidth: 2,
										// color: 'rgba(151,151,151,0.31)',
										color: 'transparent',
										zeroLineColor: '#979797',
										zeroLineBorderDashOffset: 0.5
									},

									scaleLabel: {
										display: true,
										labelString: _this.padFilters.budgetPerCapita ?
												'Budget by sector per capita US$':
												'Total Budget by Sector US$m',
										color: '#979797',
										fontSize: '14',
										fontStyle: 'bold',
										defaultFontStyle: 'Muli',
										padding: 20
									}
								}
							],
							xAxes: [
								{
									stacked: true,
									ticks: {
										beginAtZero: true,
										padding: 20,
										fontSize: 14,
										fontFamily: 'Muli'
									},
									gridLines: {
										drawBorder: true,
										stepSize: 5,
										drawTicks: false,
										zeroLineWidth: 2,
										// color: 'rgba(151,151,151,0.31)',
										color: 'transparent',
										zeroLineColor: '#979797',
										zeroLineBorderDashOffset: 0.5
									}
								}
							]
						},
						plugins: {
							datalabels: {
								display: false,
								anchor: 'end',
								align: 'start',
								padding: 20,
								font: {
									size: 33,
									weight: 700,
									family: 'Muli'
								},

								color: '#ffffff'
							},
						}
					}
				});
			});
		},
		generateTooltip() {
			const _this = this;
			return function(tooltip) {
				_this.activeData = null;

				if (!tooltip.dataPoints) return;

				const dataPoint = tooltip.dataPoints[0];
				const key = _this.colors[dataPoint.datasetIndex];
				_this.activeData = _this.data[dataPoint.index];

                _this.removeTooltip();

				let tooltipEl = document.createElement('div');
				tooltipEl.id = 'chartjs-tooltip-budget';
				tooltipEl.innerHTML = `
                <div class="chartjs-tooltip-oda__box">
					<div class="chartjs-tooltip-oda__left">
						<div class="p-text p-text-bold">Sector</div>
						<div class="p-text">${key.name}</div>
						<div class="h3 h3--bold h3--muli">$${
							_this.padFilters.budgetPerCapita ?
									_this.activeData.values[key.slug] :
									formatNumber(_this.activeData.values[key.slug])
						}</div>
					</div>
					<div class="chartjs-tooltip-oda__right">
						<div class="p-text-bold">Change from</div>
						<div class="p-text-bold">last period</div>
						<div class="h3 h3--bold h3--muli">${parseFloat(_this.activeData.change[key.slug]) > 0 ?
						'+' + _this.activeData.change[key.slug] : _this.activeData.change[key.slug]}</div>
					</div>
				</div>
				`;

				this._chart.canvas.parentNode.appendChild(tooltipEl);

				// Hide if no tooltip
				if (tooltip.opacity === 0) {
					tooltipEl.style.opacity = 0;
					return;
				}

				// Set caret Position
				tooltipEl.classList.remove('above', 'below', 'no-transform');

				if (tooltip.yAlign) {
					tooltipEl.classList.add(tooltip.yAlign);
				} else {
					tooltipEl.classList.add('no-transform');
				}

				function getBody(bodyItem) {
					return bodyItem.lines;
				}

				var positionY = this._chart.canvas.offsetTop;
				var positionX = this._chart.canvas.offsetLeft;

				// Display, position, and set styles for font
				tooltipEl.style.opacity = 1;
				tooltipEl.style.fontFamily = 'Muli';
				tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
				tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
				tooltipEl.style.top = positionY + tooltip.caretY + 'px';
				tooltipEl.style.left = positionX + tooltip.caretX + 'px';
				tooltipEl.style.padding =
					tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
			};
		},
		removeTooltip() {
			const tooltipEl = document.getElementById('chartjs-tooltip-budget');

			if (tooltipEl) {
				tooltipEl.remove();
			}
		}
	}
};
</script>

<style scoped>
@media only screen and (max-width: 991px) {
	.budget {
		width: 800px;
	}

	.budget-container {
		overflow-x: auto;
	}
}

.budget--square {
	z-index: 3;
}

.square {
	background-size: 20px 20px;
	background-image: linear-gradient(
			to right,
			rgba(151, 151, 151, 0.3) 1px,
			transparent 1px
	),
	linear-gradient(
			to bottom,
			rgba(151, 151, 151, 0.3) 1px,
			transparent 1px
	);
	position: absolute;
	left: 56px;
	top: 50px;
	z-index: 1;
	right: 0px;
	bottom: 44px;
	background-position: left bottom;
	height: auto;
	max-width: none;
	width: auto;


}
</style>
