import { mapGetters } from 'vuex';

import {
	DOWNLOAD_PDF,
	FETCH_PAD_DATA,
	DOWNLOAD_PAD_DATA,
	FETCH_PAD_DATA_SOURCES
} from '@/store/actions.type';

import {
	SET_PAD_KEYS,
	START_LOADING,
	FINISH_LOADING
} from '@/store/mutations.type';

import { APP_URL } from '@/common/config.js';

import html2canvas from 'html2canvas';

import EventBus from '@/eventBus';
import download from '@/helpers/download';
import totallyBudgetKeys from '@/data/pad/total-budget/keys';

export default {
	data() {
		return {
			interval: null
		};
	},
	computed: {
		...mapGetters(['padFilters'])
	},
	mounted() {
		EventBus.$on('IFRAME_LOADED', this._handlePadIframeLoaded);
	},
	methods: {
		async submitPadDownloadModalForm(queryParams, form) {
			if (form.format === 'csv') {
				this._downloadPadCSV(form.title);
				return;
			}

			this.$store.commit(START_LOADING);
			const previewURL = this._generatePadPreviewURL(queryParams, form);

			localStorage.setItem('download', form.format);
			this._takeSnapshotOfPadURL(previewURL);
			this._closePadModal();
		},
		async fetchChartData(landing = true) {
			if (!this.padFilters.indicator) return;

			const { indicator } = this.padFilters;

			if (indicator === 'budget-by-sector') {
				this._setBudgetBySectorKeys();
			}

			if (indicator === 'total-budget') {
				this.$store.commit(SET_PAD_KEYS, totallyBudgetKeys);
			}

			if (indicator === 'sdgs') {
				this._setSdgsKeys();
			}

			this._fetchPadData(this.padFilters.indicator);

			if (landing) {
				this._fetchPadDataSources();
			}

			if (
				this.padFilters.indicator.includes('sdgs') &&
				this.padFilters.sector
			) {
				this._fetchPadData('budget-by-sector', true);
			}
		},
		setPadRouterParams(filters) {
			const query = this._generatePadRouterParams(filters);

			this.$router
				.push({ name: 'policy-advocacy-data-explorer', query })
				.catch(err => err);
		},
		getPadParams({ budgetPerCapita, countries, sdg, sector, subSdg }) {
			return {
				countries: countries.map(c => c.id),
				sector: sector ? sector.slug : '',
				subSdg: subSdg ? subSdg.slug : '',
				sdg: sdg ? sdg.slug : '',
				budgetPerCapita
			};
		},
		setData() {
			const {
				sdg,
				subSdg,
				sector,
				indicator,
				budgetPerCapita
			} = this.$route.query;

			this.padFilters.budgetPerCapita = budgetPerCapita === 'true';
			this.padFilters.indicator = indicator ? indicator : 'total-budget';

			this.padFilters.sdg = sdg
				? this.sdgs.find(singleSdg => singleSdg.slug == sdg)
				: null;

			this.padFilters.sector = sector
				? this.sectors.find(
						singleSector => singleSector.slug === sector
				  )
				: null;

			this.padFilters.subSdg = subSdg
				? this.padFilters.sdg.subgoals.find(
						singleSubSdg => singleSubSdg.slug === subSdg
				  )
				: null;
		},
		async _handlePadIframeLoaded(body, title) {
			const fileName = title ? title : new Date().getTime();
			const downloadFormat = localStorage.getItem('download');

			const canvas = await html2canvas(body);
			const chartImage = canvas.toDataURL('image/png');

			this.$destroy();

			if (downloadFormat.includes('png')) {
				this._downloadPadPNG(chartImage, fileName);
				return;
			}

			this._downloadPadPDF(chartImage, fileName);
		},
		async _downloadPadPNG(chartImage, fileName) {
			download(chartImage, fileName);
			localStorage.removeItem('download');
		},
		async _downloadPadCSV(title) {
			const form = {
				...this.getPadParams(this.padFilters),
				...{ filename: title ? title : new Date().getTime() }
			};

			const {
				data: { link, filename }
			} = await this.$store.dispatch(DOWNLOAD_PAD_DATA, {
				indicator: this.padFilters.indicator,
				form
			});

			download(link, filename);
			this.modal = null;
		},
		async _downloadPadPDF(chartImage, fileName) {
			const form = new FormData();
			const response = await fetch(chartImage);
			const blob = await response.blob();
			const file = new File([blob], fileName, { type: 'image/png' });
			form.append('file', file, `${fileName}.png`);
			form.append('filename', fileName);

			const {
				data: { link }
			} = await this.$store.dispatch(DOWNLOAD_PDF, form);

			window.open(link);

			localStorage.removeItem('download');
		},
		_generatePadPreviewURL(queryParams, form) {
			const params = {
				...this._generatePadRouterParams(this.padFilters),
				...queryParams
			};

			let previewURLParams = Object.keys(params)
				.map(key => {
					let param = key + '=' + encodeURI(params[key]);
					if (key === 'countries') {
						param = params[key]
							.map(key => 'countries=' + key)
							.join('&');
					}
					return param;
				})
				.join('&');

			previewURLParams += '&' + form.colors.map(key => `colors=${key.replace('#', '')}`).join('&');

			return `${APP_URL}/policy-advocacy-data-explorer/preview?${previewURLParams}`;
		},
		_generatePadRouterParams(filters) {
			return {
				...this.getPadParams(filters),
				...{
					indicator: filters.indicator
				}
			};
		},
		_takeSnapshotOfPadURL(url) {
			this._removePadIframe();

			const iframe = document.createElement('iframe');
			iframe.id = 'chart-preview';
			iframe.width = 1440;
			iframe.height = 900;
			iframe.src = url;
			iframe.style.cssText =
				'position: absolute; opacity:0; z-index: -9999;bottom: 0';
			document.body.appendChild(iframe);
		},
		_closePadModal() {
			this.interval = setInterval(() => {
				const download = localStorage.getItem('download');

				if (!download) {
					this.modal = null;
					clearInterval(this.interval);
					this.$store.commit(FINISH_LOADING);
				}
			}, 500);
		},
		_removePadIframe() {
			const iframeExists = document.querySelector('#chart-preview');

			if (iframeExists) {
				iframeExists.remove();
			}
		},
		_setBudgetBySectorKeys() {
			const keys = [
				...this.sectors,
				{
					name: 'Total budget (all sectors)',
					slug: 'other',
					image: 'dummy/all-sectors.png'
				}
			];

			this.$store.commit(SET_PAD_KEYS, keys);
		},
		_setSdgsKeys() {
			const keys = [];

			if (!this.padFilters.sdg) {
				this.padFilters.sdg = this.sdgs[0];
				this.padFilters.subSdg = this.sdgs[0].subgoals[0];
			}

			keys.push({
				name: 'SDG value',
				slug: 'sdg',
				color: '#003728'
			});

			if (
				this.padFilters.indicator.includes('sdgs') &&
				this.padFilters.sector
			) {
				keys.push(this.padFilters.sector);
			}

			this.$store.commit(SET_PAD_KEYS, keys);
		},
		_fetchPadData(indicator, additionalData = false) {
			this.$store.dispatch(FETCH_PAD_DATA, {
				indicator,
				params: this.getPadParams(this.padFilters),
				additionalData
			});
		},
		_fetchPadDataSources() {
			this.$store.dispatch(FETCH_PAD_DATA_SOURCES, {
				indicator: this.padFilters.indicator,
				params: this.getPadParams(this.padFilters)
			});
		}
	}
};
