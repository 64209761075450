<template>
	<div class="budget-container">
		<div class="chart-section__bar  chart-section__budget budget">
			<canvas
				id="chart"
				v-click-outside="removeTooltip"
				@click="removeTooltip"
			></canvas>
			<div class="square"></div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import countries from '@/mixins/countries';
import imageManager from '@/mixins/image-manager';
import { formatNumber } from '@/helpers/generic';

import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.plugins.unregister(ChartDataLabels);

export default {
	name: 'TotalBudgetChart',
	mixins: [imageManager, countries],
	data() {
		return {
			chart: null,
			activeData: null
		};
	},
	computed: {
		...mapGetters(['padFilters']),
		...mapState({
			data: store => store.pad.data,
			keys: store => store.pad.keys
		}),
		colors() {
			const paramsColors = this.$route.query.colors;

			if (!paramsColors) return this.keys;

			return this.keys.map((key, index) => {
				if (paramsColors[index]) key.color = `#${paramsColors[index]}`;
				return key;
			})
		}
	},
	watch: {
		data: {
			handler: 'setChart',
			deep: true
		}
	},
	methods: {
		setChart() {
			this.$nextTick(async () => {
				this.removeTooltip();

				if (this.chart != null) {
					this.chart.destroy();
				}

				if (!this.data) return;

				var ctx = document.getElementById('chart').getContext('2d');
				ctx.height = 900;

				let max = Math.max.apply(
					Math,
					this.data.map(singleData => singleData.sum)
				);
				max = max + max * 0.1;

				const labels = this.data.map(singleData => singleData.label);
				const datasets = this.colors.map((key, index) => {
					return {
						backgroundColor: key.color,
						data: this.data.map(singleData => {
							const singleDataValues = JSON.parse(
								JSON.stringify(singleData.values)
                            );

                            if (singleData.label === 'South Africa') {
							    return singleDataValues[key.slug] * 0.4;
                            }

                            return singleDataValues[key.slug];
						})
					};
				});

				const images = await Promise.all(
					this.data.map(async singleData => {
						let image = {
							width: 70,
							height: 70
						};

						image.src = await this.toDataURL(
							require(`@/assets/imgs/flags/${singleData.slug}.png`)
						);

						return image;
					})
				);

				const _this = this;
				const customTooltip = this.generateTooltip();

				this.chart = new Chart(ctx, {
					type: 'bar',
					data: {
						labels,
						datasets
					},
					options: {
						responsive: true,
						events: ['click', 'mouseout'],
						maintainAspectRatio: false,
						layout: {
							padding: {
								top: 50
							}
						},
						legend: {
							display: false
						},
						animation: {
							onComplete: function() {
								this.options.animation.onComplete = null;
								_this.$emit('onLoad');
							}
						},
						tooltips: {
							enabled: false,
							custom: customTooltip
						},
						scales: {
							scaleShowGridLines: false,
							yAxes: [
								{
									stacked: true,
									ticks: {
										beginAtZero: true,
										display: false,
										stepSize: max / 50
										// max
									},
									gridLines: {
										drawBorder: true,
										stepSize: 1,
										drawTicks: false,
										zeroLineWidth: 2,
										// color: 'rgba(151,151,151,0.31)',
										color: '#ffffff',
										zeroLineColor: '#979797'
										// zeroLineBorderDashOffset: 0.5
									},
									scaleLabel: {
										display: true,
										labelString: this.padFilters.budgetPerCapita ? 'Budget per capita US$' : 'Total Budget US$m',
										color: '#979797',
										fontSize: '14',
										fontStyle: 'bold',
										defaultFontStyle: 'Muli',
										padding: 20
									}
								}
							],
							xAxes: [
								{
									stacked: true,
									ticks: {
										beginAtZero: true,
										padding: 20,
										fontSize: 14,
										fontFamily: 'Muli'
									},
									gridLines: {
										// drawBorder: true,
										drawBorder: false,
										stepSize: 0.5,
										drawTicks: false,
										zeroLineWidth: 2,
										// color: 'rgba(151,151,151,0.31)',
										color: '#ffffff',
										zeroLineColor: '#979797',
										zeroLineBorderDashOffset: 0.5
									},
									scaleLabel: {
										display: true,
										color: '#979797',
										fontSize: '14',
										fontStyle: 'bold',
										defaultFontStyle: 'Muli',
										padding: 20
									}
								}
							]
						},
						plugins: {
							datalabels: {
                                display: 'none',
								anchor: 'end',
								align: 'start',
								// padding: 20,
								paddingBottom: 100,
								font: {
									// size: 33,
									size: 18,
									weight: 700,
									family: 'Muli'
								},
								color: '#ffffff'
							}
						}
					}
				});
			});
		},
		removeTooltipEventListener() {
			const budgetChartEl = document.querySelector('#budget-chart');

			if (budgetChartEl) {
				budgetChartEl.removeEventListener('click', () => {});
			}
		},
		setTooltipEventListener() {
			const country = this.getCountry(this.activeData.slug);

			if (!country) return;

			document.querySelector('#budget-chart').addEventListener('click', () => {
				this.padFilters.indicator = 'budget-by-sector';
				this.padFilters.countries = [country];
			});
		},
		generateTooltip() {
			const _this = this;
			return function(tooltip) {
				_this.activeData = null;
				_this.removeTooltipEventListener();

				if (!tooltip.dataPoints) return;

				_this.activeData = _this.data[tooltip.dataPoints[0].index];

				_this.removeTooltip();

				let tooltipEl = document.createElement('div');
				tooltipEl.id = 'chartjs-tooltip-budget';
				tooltipEl.innerHTML = `
                        <div class="chart-js-tooltip-budget__top">
							<div class="chart-js-tooltip-budget__top__left">
								${
									_this.padFilters.budgetPerCapita
									? '<div class="p-text p-text-bold">Budget per capita</div>'
									: '<div class="p-text p-text-bold">Total budget </div>'
								}
								<div class="h4 h4--bold text-value">
								$${
									_this.padFilters.budgetPerCapita ?
									_this.activeData.sum : formatNumber(_this.activeData.sum)
								}
								</div>
							</div>
							<div class="chart-js-tooltip-budget__top__right">
								<div class="p-text p-text-bold">${ _this.padFilters.budgetPerCapita ? 'Change from' : 'Total budget change'}</div>
								<div class="p-text p-text-bold">${ _this.padFilters.budgetPerCapita ? '' : 'from'} last period</div>
								<div class="h4 h4--bold text-value">${parseFloat(_this.activeData.change) ? '+' + _this.activeData.change : _this.activeData.change}</div>
							</div>
                        </div>
						<div class="chart-js-tooltip-budget__line"></div>
						<div class="chart-js-tooltip-budget__top">
						<div class="chart-js-tooltip-budget__top__left">
						<div class="p-text p-text-bold">ODA est.</div>
						<div class="h6 h6--regular h6--bold text-value text-value--small">
						$${
							_this.padFilters.budgetPerCapita ?
							_this.activeData.values.oda : formatNumber(_this.activeData.values.oda)
						}
						</div>
						</div>
						<div class="chart-js-tooltip-budget__top__right">
						<div class="p-text p-text-bold">All other financing</div>
						<div class="h6 h6--regular h6--bold text-value text-value--small">
						$${
							_this.padFilters.budgetPerCapita ?
							_this.activeData.values.other : formatNumber(_this.activeData.values.other)
						}
						</div>
						</div>
						</div>
						<button
						id="budget-chart"
						class="chart-js-tooltip-budget__btn button button--small button__primary-dark">
							See sector spend
						</button>
					`;

				this._chart.canvas.parentNode.appendChild(tooltipEl);

				// Hide if no tooltip
				if (tooltip.opacity === 0) {
					tooltipEl.style.opacity = 0;
					return;
				}

				// Set caret Position
				tooltipEl.classList.remove('above', 'below', 'no-transform');

				if (tooltip.yAlign) {
					tooltipEl.classList.add(tooltip.yAlign);
				} else {
					tooltipEl.classList.add('no-transform');
				}

				function getBody(bodyItem) {
					return bodyItem.lines;
				}

				var positionY = this._chart.canvas.offsetTop;
				var positionX = this._chart.canvas.offsetLeft;

				// Display, position, and set styles for font
				tooltipEl.style.opacity = 1;
				tooltipEl.style.fontFamily = 'Muli';
				tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
				tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
				tooltipEl.style.top = positionY + tooltip.caretY + 'px';
				tooltipEl.style.left = positionX + tooltip.caretX + 'px';
				tooltipEl.style.padding =
					tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';

				_this.setTooltipEventListener();
			};
		},
		removeTooltip() {
			const tooltipEl = document.getElementById('chartjs-tooltip-budget');

			if (tooltipEl) {
				tooltipEl.remove();
			}
		}
	}
};
</script>

<style>
#chartjs-tooltip-budget {
	pointer-events: all !important;
	margin-top: 13px;
}

#chart {
	cursor: pointer;
	z-index: 2;
}

.chart-section__budget::before {
	content: '';
	bottom: 99px;
}

.chart-section__budget::after {
	content: '';
	bottom: 48px;
	height: 79%;
	z-index: 3;
}

@media only screen and (max-width: 991px) {
	.budget {
		width: 800px;
	}

	#chartjs-tooltip-budget {
		min-width: unset;
		max-width: 230px;
		width: 100%;
	}

	.budget-container {
		overflow-x: auto;
	}

	.chartjs-tooltip-oda__box {
		flex-direction: column !important;
		align-items: flex-start;
	}

	.chartjs-tooltip-oda__right {
		padding-left: 0;
		padding-top: 10px;
	}

	.chartjs-tooltip-oda__left {
		border-right: 0;
		border-bottom: 1px solid #dddddd;
		padding-bottom: 10px;
	}

	.chart-js-tooltip-budget__top {
		display: flex;
		flex-direction: column;
	}

	.chart-js-tooltip-budget__top__left {
		padding: 10px 0;
	}

	.chart-js-tooltip-budget__top__right {
		padding: 10px 0;
	}
}

.square {
	background-size: 20px 20px;
	background-image: linear-gradient(
			to right,
			rgba(151, 151, 151, 0.3) 1px,
			transparent 1px
		),
		linear-gradient(to bottom, rgba(151, 151, 151, 0.3) 1px, transparent 1px);
	max-width: 750px;
	position: absolute;
	width: 100%;
	left: 55px;
	height: 334px;
	top: 50px;
	z-index: 1;
}
</style>

<style scoped>
@media only screen and (max-width: 991px) {
	/deep/ .p--extra-small {
		line-height: 1.5;
	}
}
</style>
