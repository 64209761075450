<template>
	<div class="scriptable-container">
		<div>
			<div
				class="y-axis cursor-pointer"
				@click="showTooltip = !showTooltip"
				v-if="padFilters.sdg"
			>
				{{
					`SDG Goal ${padFilters.sdg.order}${
						padFilters.subSdg ? `.${padFilters.subSdg.order}` : ''
					}`
				}}
				<br />
				<small
					style="font-weight: lighter"
					:title="
						padFilters.subSdg ? padFilters.subSdg.description : ''
					"
					>{{
						padFilters.subSdg
							? `${padFilters.subSdg.description.slice(
									0,
									100
							  )}...`
							: padFilters.sdg.description
					}}
				</small>
			</div>
		</div>
		<div class="chart-section__scriptable scriptable">
			<canvas
				id="chart"
				class="scriptable--square"
				v-click-outside="removeTooltip"
				@click="removeTooltip"
			></canvas>
			<div class="square"></div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
	name: 'SDGsChart',
	data() {
		return {
			chart: null,
			showTooltip: false
		};
	},
	computed: {
		...mapGetters(['padFilters']),
		...mapState({
			data: store => store.pad.data,
			keys: store => store.pad.keys,
			sectors: store => store.app.sectors,
			additionalData: store => store.pad.additionalData
		})
	},
	watch: {
		data: {
			handler: 'setChart',
			deep: true
		},
		additionalData: {
			handler: 'setChart',
			deep: true
		}
	},
	beforeDestroy() {
		this.padFilters.sdg = null;
		this.padFilters.subSdg = null;
	},
	methods: {
		setChart() {
			if (!this.padFilters.sdg) return;

			this.$nextTick(() => {
				this.removeTooltip();

				if (this.chart != null) {
					this.chart.destroy();
				}

				if (!this.data || !this.data.length) return;

				var ctx = document.getElementById('chart').getContext('2d');
				ctx.height = 700;

				const labels = this.data.map(singleData => singleData.label);

				const datasets = this.generateDataset();

				const _this = this;
				const customTooltip = this.generateTooltip();

				this.chart = new Chart(ctx, {
					data: {
						labels,
						type: 'line',
						datasets
					},
					options: {
						responsive: true,
						legend: false,
						events: ['click', 'mouseout'],
						maintainAspectRatio: false,
						tooltips: {
							enabled: false,
							custom: customTooltip
						},
						animation: {
							onComplete: function() {
								this.options.animation.onComplete = null;
								_this.$emit('onLoad');
							}
						},
						scales: {
							//
							yAxes: [
								{
									type: 'linear',
									display: true,
									position: 'left',
									id: 'y-axis-1',
									scaleLabel: {
										display: false
									},
									ticks: {
										beginAtZero: true,
										display: false,
										suggestedMin: 0,
										suggestedMax: 100,
										maxTicksLimit: 2,
										max: 100,
										padding: 0
									}
								},
								{
									type: 'linear',
									display: true,
									position: 'right',
									id: 'y-axis-2',
									scaleLabel: {
										display: true,
										labelString: this.padFilters.sector
											? `${this.padFilters.sector.name} budget (US$m)`
											: '',
										color: '#29a87c',
										fontColor: '#29a87c',
										fontSize: '14',
										fontStyle: 'bold',
										defaultFontStyle: 'Muli'
									},
									// grid line settings
									gridLines: {
										drawOnChartArea: true, // only want the grid lines for one axis to show up
										fontFamily: 'Muli',
										fontSize: '14',
										zeroLineWidth: 2,
										// color: 'rgba(151,151,151,0.31)',
										color: 'transparent',
										zeroLineColor: '#979797',
										zeroLineBorderDashOffset: 0.5
									},

									ticks: {
										beginAtZero: true,
										display: false,
										suggestedMin: 0,
										suggestedMax: 100,
										maxTicksLimit: 2,
										max: 100,
										padding: 0
									}
								}
							],
							xAxes: [
								{
									stacked: false,
									ticks: {
										beginAtZero: true,
										display: true,
										padding: 20,
										fontSize: '14'
									},
									gridLines: {
										drawBorder: true,
										drawTicks: true,
										fontFamily: 'Muli',
										fontSize: '14',
										zeroLineWidth: 2,
										color: 'rgba(151,151,151,0.31)',
										zeroLineColor: '#979797',
										zeroLineBorderDashOffset: 0.5,
										borderDash: [8, 4],
										lineWidth: 2,
										lineColor: '#979797'
									}
								}
							]
						}
					}
				});
			});
		},
		generateTooltip() {
			const _this = this;
			return function(tooltip) {
				if (!tooltip.dataPoints) return;

				const dataPoint = tooltip.dataPoints[0];
				const key = _this.keys[dataPoint.datasetIndex];
				const data = _this.data.find(
					singleData => singleData.label == dataPoint.label
				);

				_this.removeTooltip();

				let tooltipEl = document.createElement('div');

				if (key && key.slug === 'sdg') {
					tooltipEl.id = 'chartjs-tooltip-sdg';
					tooltipEl.innerHTML = `
                        <div class="chartjs-tooltip-sdg__flag">
                            <div class="chartjs-tooltip-sdg__flag__box">
                                <img src="${require(`@/assets/imgs/flags/${data.slug}.png`)}" alt=""></div>
                            <div class="p-text-bold">${data.label}</div>
                        </div>
                        <div class="p p-text-bold center">
                            Goal ${_this.padFilters.sdg.order}${
						_this.padFilters.subSdg
							? `.${_this.padFilters.subSdg.order}`
							: ''
					}
                        </div>
                        <div class="h3 h3--muli h3--bold center">${
							data.values.sdg
						}</div>
                        `;
				} else {
					const sectorData = _this.additionalData.find(
						singleData => singleData.label === data.label
					);
					tooltipEl.id = 'chartjs-tooltip-budget';
					tooltipEl.innerHTML = `
                        <div class="chartjs-tooltip-oda__box">
                            <div class="chartjs-tooltip-oda__left">
                                <div class="p-text p-text-bold">Sector</div>
                                <div class="p-text">${
									_this.padFilters.sector.name
								}</div>
                                <div class="h3 h3--bold h3--muli">$${
									sectorData.values[
										_this.padFilters.sector.name
									]
								}</div>
                            </div>
                            <div class="chartjs-tooltip-oda__right">
                                <div class="p-text-bold">Change from</div>
                                <div class="p-text-bold">last period</div>
                                <div class="h3 h3--bold h3--muli">${
									sectorData.change[
										_this.padFilters.sector.name
									]
								}</div>
                            </div>
                        </div>
                    `;
				}

				this._chart.canvas.parentNode.appendChild(tooltipEl);

				// Hide if no tooltip
				if (tooltip.opacity === 0) {
					tooltipEl.style.opacity = 0;
					return;
				}

				// Set caret Position
				tooltipEl.classList.remove('above', 'below', 'no-transform');

				if (tooltip.yAlign) {
					tooltipEl.classList.add(tooltip.yAlign);
				} else {
					tooltipEl.classList.add('no-transform');
				}

				function getBody(bodyItem) {
					return bodyItem.lines;
				}

				var positionY = this._chart.canvas.offsetTop;
				var positionX = this._chart.canvas.offsetLeft;

				// Display, position, and set styles for font
				tooltipEl.style.opacity = 1;
				tooltipEl.style.fontFamily = 'Muli';
				tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
				tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
				tooltipEl.style.top = positionY + tooltip.caretY + 'px';
				tooltipEl.style.left = positionX + tooltip.caretX + 'px';
				tooltipEl.style.padding =
					tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
			};
		},
		removeTooltip() {
			const tooltipEl = document.getElementById('chartjs-tooltip-sdg');
			const tooltipElSector = document.getElementById(
				'chartjs-tooltip-budget'
			);

			if (tooltipEl) {
				tooltipEl.remove();
			}

			if (tooltipElSector) {
				tooltipElSector.remove();
			}
		},
		generateDataset() {
			const sdgsDataset = this.keys.map(key => {
				return {
					type: 'line',
					borderWidth: 2,
					fill: '#000000',
					lineTension: 0,
					pointRadius: 12,
					borderColor: '#c3c3c3',
					backgroundColor: key.color,
					data: this.data.map(singleData => singleData.values['sdg'])
				};
			});

			if (!this.padFilters.sector || !this.additionalData)
				return sdgsDataset;

			const values = this.additionalData.map(singleData =>
				parseInt(singleData.values[this.padFilters.sector.slug])
			);

			const percentageValues = this.additionalData.map(
				singleData =>
					(singleData.values[this.padFilters.sector.slug] * 100) /
					Math.max(...values)
			);

			const sectorDataset = {
				type: 'bar',
				fillColor: 'rgb(88,161,142)',
				strokeColor: 'rgb(88,161,142)',
				pointColor: 'rgb(88,161,142)',
				pointStrokeColor: 'rgb(88,161,142)',
				pointHighlightFill: 'rgb(88,161,142)',
				pointHighlightStroke: 'rgb(88,161,142)',
				backgroundColor: this.padFilters.sector.color,
				data: percentageValues
			};

			return [...sdgsDataset, ...[sectorDataset]];
		}
	}
};
</script>

<style>
#chartjs-tooltip-sdg {
	margin-top: 13px;
}

.scriptable-container {
	margin-top: 40px;
	position: relative;
	display: flex;
	flex-direction: row;
}

.y-axis {
	transform: rotate(-90deg);
	position: absolute;
	left: -168px;
	width: 338px;
	top: 40%;
	display: flex;
	justify-content: center;
	font-weight: 600;
	z-index: 50;
	flex-wrap: wrap;
}

.y-axis img {
	margin-right: 12px;
	position: relative;
}

.axis-tooltip {
	position: absolute;
	bottom: 123px;
	left: 21px;
	/*width: 300px;*/
	/*min-height: 83px;*/
	background: #ffffff;
	z-index: 50;
	border-radius: 4px;
	padding: 16px;
	box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.22);
}

.axis-tooltip::before {
	content: '';
	position: absolute;
	top: 50%;
	left: -17px;
	transform: translateY(-50%);
	width: 20px;
	height: 20px;
	background-color: #ffffff;
	box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.22);
	clip-path: polygon(50% 49%, 100% 100%, 100% 0);
	/*-webkit-clip-path: polygon(50% 49%, 100% 100%, 100% 0);*/
}

@media only screen and (max-width: 991px) {
	.scriptable {
		width: 800px;
	}

	.scriptable-container {
		overflow-x: auto;
		margin-top: 80px;
	}
}
</style>

<style scoped>
.square {
	background-size: 20px 20px;
	background-image: linear-gradient(
			to right,
			rgba(151, 151, 151, 0.3) 1px,
			transparent 1px
	),
	linear-gradient(
			to bottom,
			rgba(151, 151, 151, 0.3) 1px,
			transparent 1px
	);
	position: absolute;
	left: 45px;
	top: 0;
	z-index: 1;
	right: 34px;
	bottom: 55px;
	background-position: left bottom;
	height: auto;
	max-width: none;
	width: auto;
}

.scriptable--square {
	z-index: 3;
}
</style>
